import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/source/contexts/DrawerContext';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/Navbar';
import Footer from 'common/source/containers/Footer';
// import Banner from 'common/source/containers/BannerOsram';
import Banner from 'common/source/containers/BannerOsramCategory';

import OsramCategoryBody from '../../components/OsramCategoryBody';

import SEO from '../../components/SEO';
import config from '../../../data/SiteConfig';

class AeropuertosOsram extends React.Component {
  render() {
    const { data } = this.props;
    const { page } = data;
    const { frontmatter } = page;
    // console.log({ page });
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO
            pageTitle={`${frontmatter.title} | ${config.siteTitle}`}
            path={`/${frontmatter.slug}`}
            pageNode={page}
          />
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass='sticky-nav-active'>
              <DrawerProvider>
                <Navbar background='purple' />
              </DrawerProvider>
            </Sticky>
            <Banner
              cover={frontmatter.cover}
              title={frontmatter.title}
              subtitle={frontmatter.subtitle || frontmatter.description}
            />
            <OsramCategoryBody node={page} />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default AeropuertosOsram;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query AeropuertosOsramQuery {
    page: markdownRemark(frontmatter: { slug: { eq: "osram/aeropuertos" } }) {
      html
      timeToRead
      excerpt
      fields {
        slug
        date
      }
      frontmatter {
        slug
        title
        subtitle
        description
        cover
        thumbnail
        products
      }
    }
  }
`;
